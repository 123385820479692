<template>
  <div class="evaluation-css">
    <div class="box">
      <p>广州日京科技智能制造评估评价报告</p>
      <p>Evaluation report of intelligent manufacturing Evaluation</p>
      <router-link to="./historypage" tag="span" class="back" >返回历史页</router-link>
      <div class="contain">
        <p>提交时间：2021年5月18日 09：43</p>
        <div class="pie-chart">
          <div class="info">
            <p>成熟度等级：1级</p>
            <p>自诊断分值：5.1</p>
            <p>共答错题数：7</p>
            <p>对比同行业平均水平：高</p>
          </div>
          <div class="pie" id="pie" style="width: 320px;height:320px;">圆图</div>
        </div>
        <div class="table">
          <p>等级分值详情</p>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="date" label="性别"></el-table-column>
            <el-table-column prop="name" label="组织战略"></el-table-column>
            <el-table-column prop="address" label="组织战略"></el-table-column>
            <el-table-column prop="address" label="组织战略"></el-table-column>
            <el-table-column prop="address" label="..."></el-table-column>
            <el-table-column prop="address" width="300px"></el-table-column>
            <el-table-column prop="address" label="获得分值"></el-table-column>
            <el-table-column prop="address" label="总分值"></el-table-column>
          </el-table>
        </div>
        <div class="bar-chart" id="bar" style="width:100%;height:230px">条形统计图</div>
        <div class="grass-tab">
          <p>答题情况</p>
          <div class="grass">
            <div>
              <p>一级</p>
            </div>
            <div>
              <p>二级</p>
            </div>
            <div>
              <p>三级</p>
            </div>
            <el-checkbox v-model="checked">只看错分题</el-checkbox>
          </div>
        </div>
        <el-row class="answer">
          <el-col :span="4">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#DFE4F4"
              text-color="black"
              active-text-color="#4B74E9"
            >
              <el-submenu index="1">
                <template slot="title">
                  <span>导航一</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-1">选项1</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
          <el-col :span="19" class="answer-contain">
            <div class="one">
              <p class="title">title</p>
              <div>
                <el-radio>选项</el-radio>
                <el-radio>选项</el-radio>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      checked: false
    };
  },
  mounted() {
    this.generatePie();
    this.generateBar();
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 统计图
    generatePie() {
      var myChart = this.$echarts.init(document.getElementById("pie"));
      myChart.setOption({
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            data: [
              { value: 1048, name: "搜索引擎" },
              { value: 735, name: "直接访问" },
              { value: 580, name: "邮件营销" },
              { value: 484, name: "联盟广告" },
              { value: 300, name: "视频广告" }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      });
    },
    generateBar() {
      var myChart = this.$echarts.init(document.getElementById("bar"));
      myChart.setOption({
        title: {
          text: "行业分值对比",
          subtext: "(分值)",
          left: "30px",
          textStyle: {
            fontSize: 14
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          //颜色说明
          data: ["2011年", "2012年"],
          right: "30px"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: ["巴西", "印尼", "美国", "印度", "中国", "世界人口(万)"]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "2011年",
            type: "bar",
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          },
          {
            name: "2012年",
            type: "bar",
            data: [19325, 23438, 31000, 121594, 134141, 681807]
          }
        ]
      });
    }
  }
};
</script>

<style>
.evaluation-css .pie-chart,
.evaluation-css .table,
.evaluation-css .grass-tab,
.evaluation-css .answer {
  margin: 0 30px;
}
.evaluation-css {
  position: absolute;
  width: 100%;
  background-image: url("../../assets/evaluation/background.png");
  background-size: 100% 100%;
}
.evaluation-css .box {
  position: relative;
}
.evaluation-css .box > p:nth-of-type(1),
.evaluation-css .box > p:nth-of-type(2) {
  color: white;
  text-align: center;
}
.evaluation-css .box > .back {
  position: absolute;
  right: 30px;
  top: -13px;
  color: white;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 20px;
}
.evaluation-css .box > p:nth-of-type(1) {
  letter-spacing: 5px;
  font-size: 42px;
}
.evaluation-css .box > p:nth-of-type(2) {
  letter-spacing: 2px;
  font-size: 22px;
  margin-top: -38px;
  opacity: 0.5;
}
.evaluation-css .contain {
  width: 84%;
  background-color: white;
  margin: 0 auto 100px auto;
  border-radius: 8px;
  padding-bottom: 50px;
}
.evaluation-css .contain > p {
  text-align: end;
  color: #777777;
  padding-top: 15px;
  padding-right: 20px;
}
/* 圆形统计图项 */
.evaluation-css .pie-chart {
  display: flex;
}
.evaluation-css .pie-chart .info {
  border-radius: 8px;
  width: 200px;
  height: 200px;
  padding-left: 20px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 40px;
  line-height: 31px;
  background-color: #e6e1b242;
  border: 1px solid #ca9d6242;
}
.evaluation-css .pie-chart .pie {
  margin-left: 100px;
}
/* table */
.evaluation-css .table {
  font-size: 14px;
  font-weight: bold;
}
.evaluation-css .el-table td,
.el-table th {
  text-align: center !important;
}
.evaluation-css .el-table thead {
  color: white !important;
}
.evaluation-css .el-table th,
.evaluation-css .el-table tr {
  background-color: #4b74e9 !important;
}

.evaluation-css .bar-chart {
  background-color: #eef1fa;
  margin-top: 35px;
  padding-top: 20px;
  padding-bottom: 20px;
}
/* radio项 */
.evaluation-css .grass-tab > p {
  font-size: 14px;
  font-weight: bold;
}
.evaluation-css .grass-tab > .grass {
  display: flex;
  align-items: center;
}

.evaluation-css .grass-tab > .grass > div:nth-of-type(2),
.evaluation-css .grass-tab > .grass > div:nth-of-type(3) {
  transform: skewX(45deg);
  border-left: 8px solid white;
  background-color: #dfe4f4;
  width: 80px;
  height: 32px;
}
.evaluation-css .grass-tab > .grass > div:nth-of-type(1) {
  width: 38px;
  height: 0;
  border: 32px solid;
  border-top: none;
  border-color: #dfe4f4;
  border-right-color: transparent;
  margin-right: -16px;
}
.evaluation-css .grass-tab > .grass > div:nth-of-type(2) > p,
.evaluation-css .grass-tab > .grass > div:nth-of-type(3) > p {
  transform: skewX(-45deg);
  text-align: center;
}
.evaluation-css .grass-tab > .grass > div > p {
  margin-top: 9px;
}
.evaluation-css .grass-tab > .grass .el-checkbox {
  margin-left: 30px;
}
.evaluation-css .el-checkbox__label {
  padding-left: 6px !important;
  font-size: 12px !important;
}
.evaluation-css .answer {
  margin-top: 10px;
}
.evaluation-css .el-submenu__title,
.evaluation-css .el-menu-item {
  font-size: 12px !important;
  height: 47px;
  line-height: 47px;
}
.evaluation-css .el-submenu__title:hover {
  background-color: #4b74e9 !important;
}
.evaluation-css .el-menu-item:hover {
  background-color: #4b75e985 !important;
}
.el-menu-item-group__title {
  display: none;
}
.evaluation-css .answer-contain {
  margin-left: 20px;
}
.evaluation-css .answer-contain .one {
  border: 1px solid #bfbfbf83;
}
.evaluation-css .answer-contain .title {
  background-color: #bfbfbf69;
  padding: 12px 20px;
  font-size: 13px;
  font-weight: bold;
  margin-top: 0;
}
.evaluation-css .el-radio {
  display: block;
  margin-bottom: 15px;
}
.evaluation-css .answer-contain .one > div {
  padding-left: 20px;
}
</style>